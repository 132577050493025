import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="pagecover">
        <h1 className="ux-text ux-text-title ux-text-size5 ux-text-feedback-critical">
            {error.status && `${error.status} `}
            {error.statusText || error.message}
        </h1>
        <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}