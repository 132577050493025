import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./errorPage";
import Table1Page from './projects/dataTable/TablePage';
import Table2Page from './projects/dataTable2/TablePage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div className="pagecover"><h1 className='ux-text ux-text-title ux-text-size5'>Prototype Projects</h1></div>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/table1",
    element: <Table1Page />,
  },
  {
    path: "/table2",
    element: <Table2Page />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
