import mData from './MOCK_DATA_DOMAINS.json';
import moment from 'moment';
import { Menu, MenuButton, MenuList, MenuItem } from '@ux/menu';
import '@ux/menu/styles';
import MenuIcon from '@ux/icon/menu';
import '@ux/icon/menu/index.css';
import Button from '@ux/button';
import '@ux/button/styles';

export const data = mData;

export const columns = [
  {
    header: 'Domain Name',
    accessorKey: 'domain',
    footer: 'Domain Name',
    sortDescFirst: true,
  },
  {
    header: '',
    accessorKey: 'actions',
    footer: 'Actions',
    cell: props => (
      <Menu size='small' id={`${props.column.id}-${props.row.id}`}>
        <MenuButton size='small' icon={<MenuIcon />} aria-label='Open Action Menu' tabIndex={-1} />
        <MenuList>
          <MenuItem>Renew Now</MenuItem>
          <MenuItem>List for Sale</MenuItem>
        </MenuList>
      </Menu>
    ),
    enableSorting: false,
  },
  {
    header: 'Expiration',
    accessorKey: 'expiration',
    footer: 'Expiration',
    cell: info => moment(info.getValue()).format('MMM D, YYYY'),
    meta: {
      align: 'end'
    },
    sortDescFirst: true,
  },
  {
    header: 'Auto-renew',
    accessorKey: 'renew',
    footer: 'Auto-renew',
    cell: info => <Button design='inline' text={info.getValue()} href='#' />,
    sortDescFirst: true,
  },
  {
    header: 'Estimated Value',
    accessorKey: 'estimated_value',
    footer: 'Estimated Value',
    cell: info => info.getValue() < 100 ? <span>Less than $100</span> : <span>${info.getValue()}</span>,
    meta: {
      align: 'end'
    },
    enableTooltip: true,
    sortDescFirst: true,
  },
  {
    header: 'Privacy',
    accessorKey: 'privacy',
    footer: 'Privacy',
    cell: info => <Button design='inline' text={info.getValue()} href='#' />,
    sortDescFirst: true,
  },
  {
    header: 'Protection Plan',
    accessorKey: 'protection',
    footer: 'Protection Plan',
    cell: info => <Button design='inline' text={info.getValue()} href='#' />,
    sortDescFirst: true,
  },
  {
    header: 'Lock',
    accessorKey: 'lock',
    footer: 'Lock',
    cell: info => <Button design='inline' text={info.getValue()} href='#' />,
    sortDescFirst: true,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    footer: 'Status',
    sortDescFirst: true,
  },
  {
    header: 'Nameservers',
    accessorKey: 'nameservers',
    footer: 'Nameservers',
    sortDescFirst: true,
  },
  {
    header: 'Ownership Date',
    accessorKey: 'ownership_date',
    footer: 'Ownership Date',
    cell: info => moment(info.getValue()).format('MMM D, YYYY'),
    meta: {
      align: 'end'
    },
    sortDescFirst: true,
  }
];