import React from 'react';
import Table from './Table';
import { data, columns } from './data/domains';
import SharedLayout from './SharedLayout';

function TablePage() {
  return (
    <SharedLayout id="table2">
      <Table data={ data } columns={ columns } />
    </SharedLayout>
  );
}

export default TablePage;
