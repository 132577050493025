/* eslint-disable react-hooks/exhaustive-deps */
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel
} from '@tanstack/react-table';
import { useMemo, useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import './Table.scss';
import Pagination from '@ux/pagination';
import '@ux/pagination/styles';
import ArrowUp from '@ux/icon/arrow-up';
import '@ux/icon/arrow-up/index.css';
import ArrowDown from '@ux/icon/arrow-down';
import '@ux/icon/arrow-down/index.css';
import Button from '@ux/button';
import '@ux/button/styles';

import AlertIcon from '@ux/icon/alert';
import '@ux/icon/alert/index.css';
import Tooltip from '@ux/tooltip';
import '@ux/tooltip/styles';
import Dialog from '@ux/dialog';
import '@ux/dialog/styles';
import text from '@ux/text';
import '@ux/text/styles';

function TooltipEstimated() {
  const [show, setShow] = useState(false);
  const anchorRef = useRef(null);

  const tooltip = (
    <Tooltip anchorRef={anchorRef} onClose={() => setShow(false)} id='tooltip-estimated'>
      <Dialog
        onClose={() => setShow(false)}
        title={<text.h3 text='Estimated Value (USD)' as='title' size={-2} />}
        as='block'
      >
        <text.div>
          The Estimated Value is based on an algorithm utilizing data available to it
          to help estimate predicted sale price of domains. However, we do not make any
          guarantee or other promises to any results that may be obtained from your
          purchase or sale of any domain, and this is not intended as a solicitation or
          offer to buy any domain. It is your responsibility to independently assess and
          determine the value of any domain you may purchase or sell. We shall not be
          liable for any losses you or anyone else suffers as a result of relying on the
          Estimated Value, which includes not being liable for any loss of profit, loss
          of bargain, loss of capital through over-payment or under-sale or for any
          indirect, special or consequential loss.
        </text.div>
      </Dialog>
    </Tooltip>
  );
  return (
    <>
      <Button
        ref={anchorRef}
        icon={<AlertIcon />}
        onClick={() => setShow(!show)}
        aria-describedby='tooltip-estimated'
        aria-label='Warning tooltip'
        size='small'
        design='inline'
      />
      {show && tooltip}
    </>
  );
}

export default function Table(props) {
  const [sorting, setSorting] = useState([{ id: 'domain' }]);
  const [filtering, setFiltering] = useState('');
  const [paginationOffset, setPaginationOffset] = useState(0);

  // overflow
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);
  const scrollBoxRef = useRef(null);

  // sticky
  const targetRef = useRef(null);
  const stickyRef = useRef(null);

  const dataStable = useMemo(() => props.data, []);

  let scrollBox, scrollBoxEnd;

  const table = useReactTable({
    data: dataStable,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    enableSortingRemoval: false
  });

  // Highlighting
  const isHighlighted = (value) => {
    // Would need a performance check
    const newValue = String(value).toLowerCase();
    const newFilter = filtering.toLowerCase();
    return newValue.includes(newFilter);
  }

  // Pagination
  const paginationOnChange = (nextOffset) => {
    table.setPageIndex(Math.floor(nextOffset / table.getState().pagination.pageSize));
    setPaginationOffset(nextOffset);
  }

  // Overflow
  function checkOverflow() {
    scrollBoxEnd = scrollBox.scrollWidth - scrollBox.offsetWidth;
  }
  function handleScroll() {
    const value = scrollBox.scrollLeft;
    setShowLeft(value > 0 ? true : false);
    setShowRight(value < scrollBoxEnd ? true : false);
  }

  // Sticky
  function stickyTableHeader() {
    const scrollbox = scrollBoxRef.current;
    const target = targetRef.current;
    const sticky = stickyRef.current;
    const rows = target.getElementsByTagName("tr");
    const lastRow = rows[rows.length - 1];
    const targetHeight = target.getBoundingClientRect().height;
    const stickyHeight = sticky.getBoundingClientRect().height;
    const lastRowHeight = lastRow.getBoundingClientRect().height;
    const stoppingPoint = targetHeight - stickyHeight - lastRowHeight;

    function applySticky(value) {
      sticky.style.transform = `translateY(${value}px)`;
    }
    function stickyScrollbarReset() {
      stickyScrollbar(null);
    }
    function stickyScrollbar(targetRect) {
      targetRect = targetRect || target.getBoundingClientRect();

      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (targetRect.bottom > windowHeight) {
        scrollbox.style.height = `${windowHeight - targetRect.top}px`;
      } else {
        scrollbox.style.height = "auto";
      }
    }
    function scrollLogic() {
      const targetRect = target.getBoundingClientRect();
      const dist = targetRect.top;

      stickyScrollbar(targetRect);
      if (dist > 1) {
        applySticky(0);
        sticky.setAttribute("data-pinned", false);
      } else {
        const value = dist / -1;
        if (value < stoppingPoint) {
          applySticky(value);
          sticky.setAttribute("data-pinned", true);
        }
      }
    }
    const onIntersection = (entries, observer) => {
      for (const { isIntersecting } of entries) {
        if (isIntersecting) {
          stickyScrollbar();
          window.addEventListener("scroll", scrollLogic);
          window.addEventListener("resize", throttle(stickyScrollbarReset, 150));
        } else {
          window.removeEventListener("scroll", scrollLogic);
          window.removeEventListener("resize", throttle(stickyScrollbarReset, 150));
          sticky.setAttribute("data-pinned", false);
        }
      }
    };
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0
    };
    const observer = new IntersectionObserver(onIntersection, options);
    observer.observe(target);
  }

  useEffect(() => {
    // Sticky
    stickyTableHeader();
    // Overflow
    scrollBox = scrollBoxRef.current;
    checkOverflow();
    handleScroll();
    scrollBox.addEventListener("scroll", throttle(handleScroll, 100));
    return () => scrollBox.removeEventListener("scroll", throttle(handleScroll, 100));
  }, [showLeft, showRight]);

  return (
    <div className='ta-controls'>
      {props.showFilter && <input type='search' value={filtering} onChange={(e) => setFiltering(e.target.value)} placeholder='Search Table' />}
      <div
        id='ta-wrapper'
        className='ta-wrapper'
        data-showleft={showLeft}
        data-showright={showRight}
      >
        <div id='ta-scroll' className='ta-scroll box' ref={scrollBoxRef}>
          <table className='ta' ref={targetRef}>
            <thead ref={stickyRef} data-pinned="false">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const isSorted = !!header.column.getIsSorted();
                    const sortEnabled = header.column.columnDef.enableSorting !== false;
                    const sortDirection = header.column.getIsSorted();
                    const alignment = header.column.columnDef.meta?.align;
                    const tooltipEnabled = header.column.columnDef.enableTooltip === true;
                    return (
                      <th
                        key={header.id}
                        id={header.column.columnDef.accessorKey}
                        className={alignment ? `ta-align-${alignment}` : null}
                        tabIndex={sortEnabled ? 0 : null}
                        data-sorted={isSorted}
                      >
                        <div className='ta-th-layout'>
                          <span
                            onClick={ header.column.getToggleSortingHandler() }>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </span>
                          {tooltipEnabled && <TooltipEstimated />}
                          {sortEnabled &&
                            <Button
                              icon={sortDirection === 'desc' ? <ArrowDown /> : <ArrowUp />}
                              onClick={header.column.getToggleSortingHandler()}
                              className='ta-sort-button'
                              aria-label={sortDirection === 'desc' ? 'Sort Descending' : 'Sort Ascending'}
                              size='small'
                              design='inline'
                            />
                          }
                        </div>
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, i) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      data-highlight={filtering ? isHighlighted(cell.getValue()) : 'false'}
                      className={cell.column.columnDef.meta?.align ? `ta-align-${cell.column.columnDef.meta.align}` : null}
                    >
                      <span className='highlighter'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='ta-pagination box'>
        <div className='ux-text'>
          {paginationOffset + 1}-{paginationOffset + table.getPaginationRowModel().rows.length}
          {' of '}
          {table.getPrePaginationRowModel().rows.length} items
        </div>

        <Pagination
          total={table.getPrePaginationRowModel().rows.length}
          limit={table.getState().pagination.pageSize}
          offset={paginationOffset}
          onChange={paginationOnChange}
          id='pagination'
        />
      </div>
    </div>
  )
}