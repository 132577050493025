import React from 'react';
import { useState } from 'react';
import './SharedLayout.scss';
import Button from '@ux/button';
import '@ux/button/styles';
import ExpandIcon from '@ux/icon/sidebar-expand';
import '@ux/icon/sidebar-expand/index.css';
import CollapseIcon from '@ux/icon/sidebar-collapse';
import '@ux/icon/sidebar-collapse/index.css';
import CheckboxIcon from '@ux/icon/checkbox-list-filled';
import '@ux/icon/checkbox-list-filled/index.css';
import TransfersIcon from '@ux/icon/arrows-circle';
import '@ux/icon/arrows-circle/index.css';
import SettingsIcon from '@ux/icon/settings';
import '@ux/icon/settings/index.css';
import DnsIcon from '@ux/icon/create-nav';
import '@ux/icon/create-nav/index.css';
import BellIcon from '@ux/icon/bell';
import '@ux/icon/bell/index.css';
import CartIcon from '@ux/icon/cart';
import '@ux/icon/cart/index.css';
import AppsIcon from '@ux/icon/apps';
import '@ux/icon/apps/index.css';
import HamburgerIcon from '@ux/icon/hamburger';
import '@ux/icon/hamburger/index.css';
import HelpIcon from '@ux/icon/help';
import '@ux/icon/help/index.css';
import text from '@ux/text';
import '@ux/text/styles';

function SharedLayout(props) {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [animating, setAnimating] = useState(false);

  return (
    <div id={props.id} className='page' data-expanded={sidebarExpanded} data-animating={animating} onTransitionEnd={() => setAnimating(false)}>
      <div className='sidebar'>
        <div className='sidebar-header'>
          <Button
            icon={sidebarExpanded ? <CollapseIcon /> : <ExpandIcon />}
            onClick={() => { setAnimating(true); setSidebarExpanded(!sidebarExpanded) }}
            aria-label={sidebarExpanded ? 'Collapse Sidebar' : 'Pin Sidebar'}
            size='small'
            id='sidebar-toggle'
          />
          <text.div className='logo' id='logo-desktop' text='Real Estate Domains' as='title'  size={-1} />
          <text.div className='logo' id='logo-mobile' text='RED' as='title'  size={-2} />
        </div>
        <div className='sidebar-content'>
          <button className='nav-item active'>
            <CheckboxIcon />
            <span className='nav-item-text'>Portfolio</span>
          </button>
          <button className='nav-item' href='#'>
            <DnsIcon />
            <span className='nav-item-text'>DNS</span>
          </button>
          <button className='nav-item' href='#'>
            <TransfersIcon />
            <span className='nav-item-text'>Transfers</span>
          </button>
          <button className='nav-item' href='#'>
            <SettingsIcon />
            <span className='nav-item-text'>Settings</span>
          </button>
        </div>
      </div>
      <div className='main'>
        <div className='box'>
          <div className='topbar'>
            <Button
                icon={<HamburgerIcon />}
                aria-label='Menu'
                id='menu'
              />
            <div className='utilities'>
              <Button
                icon={<HelpIcon />}
                text='Help Center'
                id='help-center'
              />
              <Button
                icon={<BellIcon />}
                aria-label='Alerts'
              />
              <Button
                icon={<AppsIcon />}
                aria-label='My Products'
              />
              <Button
                icon={<CartIcon />}
                aria-label='Shopping Cart'
              />
            </div>
          </div>
          <text.h1 text='Domain Portfolio' as='heading' size={0} />
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default SharedLayout;